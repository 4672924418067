import { DASHBOARD} from "@/services/apis";
import client from "@/state/axios/client";

export const exportFile = (data) => {
    return new Promise((resolve,reject)=>{
      let params = data.params === undefined ?  `?page=${data.page}&per_page=${data.per_page}&search=${data.search || ''}` : data.params;
      client({
            method : 'GET',
            url: data.resource + params,
          responseType: 'blob'
      }).then(response => resolve(response))
          .catch((error)=>reject(error.response))
    })
}
export const get = (data) => {
    return new Promise((resolve,reject)=>{
      let params = data.params === undefined ?  `?page=${data.page}&per_page=${data.per_page}&search=${data.search || ''}` : data.params;
      client({
            method : 'GET',
            url: data.resource + params,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const create = (module)=>{
    return new Promise((resolve,reject)=>{
        let path = module.url ? `/${module.url}/create/${module.param}` : `/${module}/create`
        client({
            method : 'GET',
            url:path,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const show = (module,id)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module+'/'+id,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const edit = (module,modelId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module+`/${modelId}/edit`,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const store = (module,data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const storeWithFiles = (module,data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module,
            data:data,
            headers: {
                ...client.defaults.headers,
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const changeStatus = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:"v1/orders/change/status",
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const dashboard = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:DASHBOARD,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const updateFormData = (module,data)=>{
    let requestData = data.id?data.data:data;
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module+'/'+data.id,
            data:requestData
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const update = (module,data,id)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module+"/"+id,
            data:data,
        }).then(response => resolve(response))
            .catch(error=>reject(error))
    })
}
export const disableUser = (userId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:"users/disable/"+userId,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const destroy = (module,modelId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'DELETE',
            url:module+'/'+modelId,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const invalidatePromoCode = (module,modelId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:module+'/invalidate/'+modelId,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const validateProduct = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:"validate/products",
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const assignOrder = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:"orders/assign",
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const changeOrderStatus = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:"v1/orders/change/status",
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const validateShootingSchedule = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:"shooting-schedules/validate/" + data.id,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const rejectShootingSchedule = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:"shooting-schedules/reject/" + data.id,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const toggleProductInStock = (itemId, merchantId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:"merchant-products/toggle-in-stock/" + merchantId + "/" + itemId,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const validateRecipe = (itemId) => {
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:`recipes/${itemId}/validate`,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const validateUnavailabilityRequest = (requestID, approved = true, reason = null) => {
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:`unavailability_requests/${requestID}/validate`,
            data: {
                approved : approved ? 1 : 0,
                reason : reason,
                '_method' : "PUT",
            }
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const rejectRecipe = (itemId) => {
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:`recipes/${itemId}/reject`,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
